<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card class="product-add-wrapper">
      <validation-observer ref="addProductValidation">
        <!-- form -->
        <b-form>
          <b-row>
            <b-col
              md="12"
            >
              <label for="description-form">Options for translate each program category</label>
              <transition-group
                id="description-form"
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="300"
              >
                <b-card-body
                  v-for="index in countPostCategory"
                  :key="index"
                >
                  <!-- repeater from -->
                  <b-col cols="12">
                    <div class="border rounded p-2">
                      <div
                        class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                      >
                        <feather-icon
                          v-if="arrayPostCategory.length > 1"
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItemDescription(index - 1)"
                        />
                      </div>
                      <b-row>
                        <!-- Name -->
                        <b-col md="6">
                          <b-form-group
                            label="Name"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Name"
                              rules="required"
                            >
                              <b-form-input
                                v-model="arrayPostCategory[index - 1].name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Language code -->
                        <b-col md="6">
                          <b-form-group
                            label="Language"
                            label-for="product-add-title"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Language"
                              rules="required"
                            >
                              <v-select
                                id="product-add-category"
                                v-model="arrayPostCategory[index - 1].languageCode"
                                :options="lanCodeOptions"
                                item-disabled="disable"
                                :clearable="false"
                                :selectable="(option) => {
                                  return !arrayPostCategory.find(x => x.languageCode.value === option.value)
                                }"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Description -->
                        <b-col md="12">
                          <b-form-group
                            label="Description"
                            class="mb-2"
                          >
                            <b-form-input
                              v-model="arrayPostCategory[index - 1].description"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-form-group
                        v-slot="{ ariaDescribedby }"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Default"
                          rules="required"
                        >
                          <b-form-radio
                            v-model="numberDefault"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios"
                            :value="index - 1"
                          >
                            Default language
                          </b-form-radio>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-card-body>
              </transition-group>
              <b-col
                cols="12"
                class="pb-2"
              >
                <b-button
                  v-if="lanCodeOptions.length > arrayPostCategory.length"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="ml-1"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemFormDescription"
                >
                  Add post category in another language
                </b-button>
              </b-col>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Featured Image
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="imgSrc"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <div>
                      <small class="text-muted">Required image resolution 850x225, image size 5mb.</small>
                      <b-card-text class="my-50">
                        <!-- <b-link id="blog-image-text">
                      {{ imgFile ? imgFile.name : 'banner.jpg' }}
                    </b-link> -->
                      </b-card-text>
                      <div class="d-inline-block">
                        <validation-provider
                          #default="{ errors }"
                          name="Featured image"
                          rules="required"
                        >
                          <b-form-file
                            ref="refInputEl"
                            v-model="imgFile"
                            accept=".jpg, .png, .gif"
                            placeholder="Choose file"
                            @change="onFileChange "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                      </div>
                    </div>

                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <b-col cols="12">
              <b-button
                v-if="!$route.params.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="confirmPost"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :to="{ name: 'post-category' }"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      <!--/ form -->
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BCardBody,
  BFormRadio,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BFormFile,
  BImg,
  BOverlay,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
// import { QuillEditor } from '@vueup/vue-quill'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import router from '@/router'
import { slugify, compressedImage, dataURLtoFile } from '@/utils/appUtils'
import addProgramCategory from './addPostCategory'

export default {
  components: {
    vSelect,
    BFormRadio,
    BCardBody,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BFormFile,
    BImg,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      numberDefault: null,
      snowOption: {
        placeholder: '',
        theme: 'snow',
      },
      required,
    }
  },
  computed: {
    ...mapGetters('app', ['lanCodeOptions', 'statusOptions']),
  },
  setup() {
    const {
      countPostCategory,
      arrayPostCategory,
      slug,
      imgID,
      imgFile,
      imgSrc,
    } = addProgramCategory()
    return {
      arrayPostCategory,
      countPostCategory,
      slug,
      imgID,
      imgFile,
      imgSrc,
    }
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      // this.compressedImage(files[0])
      this.imgSrc = e.target.result
      this.imgFile = files[0]
      const theReader = new FileReader()
      theReader.onloadend = async () => {
        this.imgSrc = await theReader.result
      }
      theReader.readAsDataURL(files[0])
    },
    compressedImage(file) {
      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        // Load the image
        const reader = new FileReader()
        reader.onload = readerEvent => {
          const image = new Image()
          image.onload = () => {
            const canvas = document.createElement('canvas')
            const maxSize = 544; let
              { width } = image
            let { height } = image
            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width
                width = maxSize
              }
            } else if (height > maxSize) {
              width *= maxSize / height
              height = maxSize
            }
            canvas.width = width
            canvas.height = height
            canvas.getContext('2d').drawImage(image, 0, 0, width, height)
            const dataUrl = canvas.toDataURL('image/jpeg')
            this.imgFile = dataURLtoFile(dataUrl, file.name)
          }
          image.src = readerEvent.target.result
        }
        reader.readAsDataURL(file)
      }
    },
    addNewItemInItemFormDescription() {
      this.arrayPostCategory.push({
        name: '',
        languageCode: '',
        description: '',
      })
      this.countPostCategory += 1
    },
    removeItemDescription(index) {
      this.arrayPostCategory.splice(index, 1)
      this.countPostCategory -= 1
    },
    confirmPost() {
      this.$refs.addProductValidation.validate().then(success => {
        if (success) {
          this.loading = true
          const formData = new FormData()
          formData.append('file', this.imgFile)
          formData.append('name', 'hehe')
          store.dispatch('program/uploadImage', formData).then(response => {
            this.imgID = response.body.id
            this.addPost()
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.error,
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
            this.loading = false
          })
        }
      })
    },
    addPost() {
      const programDefault = this.arrayPostCategory[this.numberDefault]
      const arrPostPayload = [...this.arrayPostCategory]
      arrPostPayload.splice(this.numberDefault, 1)
      const payload = {
        name: programDefault.name,
        description: programDefault.description,
        iconMediaId: parseInt(this.imgID, 10),
        translations: arrPostPayload.map(x => ({
          name: x.name,
          description: x.description,
          languageCode: x.languageCode.value,
          isDefaultLanguage: false,
        })),
      }
      payload.translations.push({
        name: programDefault.name,
        description: programDefault.description,
        languageCode: programDefault.languageCode.value,
        isDefaultLanguage: true,
      })
      store.dispatch('postCategory/addPostCategory', payload).then(response => {
        if (response.request.status === 201) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create post category successfuly',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          router.push('/post-category')
        }
      }).catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: e.response.data.error,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
// @import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.ql-container {
  min-height: inherit;
}
.vs__dropdown-option--disabled {
    display: none;
}
</style>
