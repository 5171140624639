import { ref } from '@vue/composition-api'

export default function addProduct() {
  const imgID = ref(null)
  const imgFile = ref(null)
  const imgSrc = ref('https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Example_image.svg/600px-Example_image.svg.png')
  const arrayPostCategory = [
    {
      name: '',
      languageCode: '',
      description: '',
    },
  ]
  const countPostCategory = ref(1)
  const slug = ref('')
  return {
    slug,
    arrayPostCategory,
    countPostCategory,
    imgID,
    imgFile,
    imgSrc,
  }
}
